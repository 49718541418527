import favbradescard from '../assets/favicos/favbradescard.png'
import favcya from '../assets/favicos/favcya.png'
import favbodega from '../assets/favicos/favbodega.png'
import favpromoda from '../assets/favicos/favpromoda.png'
import favshasa from '../assets/favicos/favshasa.png'
import favwoolworth from '../assets/favicos/favwoolworth.png'
import { SvgImage} from "../assets/SVGAhorro/SvgImage";
import tableCYA from '../assets/SVGAhorro/TableCYA.svg'
import tableBA from '../assets/SVGAhorro/TableBA.svg'
import tableBC from '../assets/SVGAhorro/TableBC.svg'
import tableDS from '../assets/SVGAhorro/TableDS.svg'
import TablePO from '../assets/SVGAhorro/TablePO.svg'
import TableSS from '../assets/SVGAhorro/TableSS.svg'
import React from "react";

export const validateNameId = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.primaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.primaryColor;
            break;
        case "bc/general":
            return brand.tertiaryColor;
            break;
        default:
            return "#000";
    }
}

export const CostColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.tertiaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const TermCodColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.secondaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const PhoneColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.secondaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const PhoneColorSolicitar = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.primaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.tertiaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const ButtonFilterColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.secondaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.tertiaryColor;
            break;
        case "bc/general":
            return brand.tertiaryColor;
            break;
        default:
            return "#000";
    }
}
export const IndicatorListPromoColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.secondaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.secondaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const PhoneIconColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.tertiaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.tertiaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.secondaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}


export const validatePhoneNumber=(phone:string)=>{
    if (phone){
        let phone_new:string = phone.replace(/\s/g, '');

        return phone_new.match(/.{1,2}/g)?.join(" ")
    }else {
        return  ""
    }

}


export const ValidateTitleDescByCompany = (name: string = "") => {
    switch (name) {
        case "clubdebeneficios/cya":
            return {
                title:"Club de Beneficios de C&A de bradescard | Disfruta de beneficios únicos para ti",
                desc:"¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía Club de Beneficios de \n" +
                    "C&A bradescard tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, ¡Conoce \n" +
                    "más!",
                icon:favcya,
            };
            break;
        case "basistencia/bodegaaurrera":
            return {
                title:"B Asistencia de CrediBodega bradescard | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía B Asistencia de \n" +
                    "CrediBodega bradescard tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, \n" +
                    "¡Conoce más!",
                icon:favbodega,
            };
            break;
        case "proasistencia/promoda":
            return {
                title: "Pro Asistencia de bradescard Promoda | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía Pro Asistencia de \n" +
                    "bradescard Promoda tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, \n" +
                    "¡Conoce más!",
                icon: favpromoda

            };
            break;
        case "asistenciaplus/shasa":
            return {
                title: "Asistencia Plus de Shasa bradescard | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? con tu membresía Asistencia Plus de \n" +
                    "Shasa bradescard tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, ¡Conoce\n" +
                    "más!",
                icon: favshasa

            };
            break;
        case "asistenciatotal/gcc":
            return {
                title: "Asistencia Total de Gana más bradescard | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? " +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía asistencia Total de" +
                    "Gana más bradescard tendrás acceso a doctores, cerrajeros, electricistas, veterinarios," +
                    "¡Conoce más!",
                icon: favwoolworth

            };
            break;
        case "bc/general":
            return {
                title: "Asistencias b plus bradescard | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía b plus bradescard \n" +
                    "tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, ¡Conoce más!",
                icon: favbradescard
            };
            break;
        default:
            return {
                title: "Asistencias b plus bradescard | Disfruta de beneficios únicos para ti",
                desc: "¿Necesitas una consulta médica? ¿Olvidaste las llaves y no tienes cómo entrar a tu casa? \n" +
                    "¿Tu auto se quedó parado y necesitas una grúa? Con tu membresía b plus bradescard \n" +
                    "tendrás acceso a doctores, cerrajeros, electricistas, veterinarios, ¡Conoce más!",
                icon: favbradescard
            };
    }
}

export const IconColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.secondaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.primaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.tertiaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}

export const VerMasColor = (name: string = "", brand: any) => {
    switch (name) {
        case "clubdebeneficios/cya":
            return brand.primaryColor;
            break;
        case "basistencia/bodegaaurrera":
            return brand.primaryColor;
            break;
        case "proasistencia/promoda":
            return brand.primaryColor;
            break;
        case "asistenciaplus/shasa":
            return  brand.secondaryColor;
            break;
        case "asistenciatotal/gcc":
            return  brand.secondaryColor;
            break;
        case "bc/general":
            return brand.primaryColor;
            break;
        default:
            return "#000";
    }
}


export const eventScrollDown = (section="section-asistencias",pos="start") => {
    const element = document.getElementById(section);
    if (element) {
            // @ts-ignore
        element.scrollIntoView({ behavior: 'smooth', block: pos});
    }
};

export const textCompanySocioName = (name: string = "") => {
    switch (name) {
        case "clubdebeneficios/cya":
            return "C&A bradescard";
            break;
        case "basistencia/bodegaaurrera":
            return "CrediBodega bradescard";
            break;
        case "proasistencia/promoda":
            return "bradescard Promoda";
            break;
        case "asistenciaplus/shasa":
            return  "shasa bradescard";
            break;
        case "asistenciatotal/gcc":
            return  "Gana más bradescard";
            break;
        case "bc/general":
            return "bradescard";
            break;
        default:
            return "bradescard";
    }
}

export const validateAhorroCompany = (name: string = "") => {
    switch (name) {
        case "clubdebeneficios/cya":
            return (<SvgImage imageUrl={tableCYA}/>);
            break;
        case "basistencia/bodegaaurrera":
            return (<SvgImage imageUrl={tableBA}/>);
            break;
        case "proasistencia/promoda":
            return (<SvgImage imageUrl={TablePO}/>);
            break;
        case "asistenciaplus/shasa":
            return (<SvgImage imageUrl={TableSS}/>);
            break;
        case "asistenciatotal/gcc":
            return (<SvgImage imageUrl={tableDS}/>);
            break;
        case "bc/general":
            return (<SvgImage imageUrl={tableBC}/>);
            break;
        default:
            return (<SvgImage imageUrl={tableBC}/>);
    }
}

export const convertStringToJSX = (jsxString: string) => {
    // Reemplazar 'class' por 'className' y otros cambios necesarios para JSX
    jsxString = jsxString
        .replace(/class=/g, "className=") // class -> className
        .replace(/for=/g, "htmlFor=") // for -> htmlFor
        .replace(/style="(.*?)"/g, (match, styles) => {
            // Convertir el atributo style en un objeto JSX
            const styleObject: Record<string, string> = {};
            styles.split(";").forEach((style:any) => {
                const [prop, val] = style.split(":");
                if (prop && val) {
                    styleObject[prop.trim()] = val.trim();
                }
            });
            return `style={${JSON.stringify(styleObject)}}`;
        })
        .replace(/<br>/g, "<br />"); // Autocierre de <br>

    // Crear un contenedor temporal para procesar el HTML
    const template = document.createElement("template");
    template.innerHTML = jsxString.trim(); // Convertir string en elementos HTML

    // Función recursiva para convertir nodos HTML en JSX
    const parseNode = (node: ChildNode): any => {
        if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent; // Devolver texto plano
        }

        if (node.nodeType !== Node.ELEMENT_NODE) {
            return null; // Ignorar nodos no válidos
        }

        const element = node as HTMLElement;
        const tagName = element.tagName.toLowerCase();

        // Convertir atributos a un objeto JSX
        const attributes: Record<string, any> = {};
        Array.from(element.attributes).forEach((attr) => {
            let name = attr.name;
            let value = attr.value;

            // Convertir "style" a objeto JSX
            if (name === "style") {
                const styleObject: Record<string, string> = {};
                value.split(";").forEach((style) => {
                    const [prop, val] = style.split(":");
                    if (prop && val) {
                        styleObject[prop.trim()] = val.trim();
                    }
                });
                attributes[name] = styleObject;
            } else {
                attributes[name] = value;
            }
        });

        // Convertir hijos en JSX
        const children = Array.from(node.childNodes).map(parseNode);

        return React.createElement(tagName, attributes, ...children);
    };

    return parseNode(template.content.firstChild!);
};





