import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {useConfigurationStore} from "../../store/useConfigurationStore";

const Footer: React.FC = () => {
    const {selectedConfiguration} = useConfigurationStore();

    return (
        <>
            {
                !selectedConfiguration.loading &&
              <Box
                component={'footer'}
                sx={{
                    display: "flex",
                    width: "100%",
                    height: {xs: 'auto', sm: "200px"},
                    padding: "27px 0px",
                    justifyContent: "center",
                    gap: "10px",
                    borderBottom: "1px solid #F0F0F0",
                    background: "var(--Black, #212121)",
                    boxShadow: "4px 6px 16px 0px rgba(33, 33, 33, 0.05)"

                }}
                bottom="0px"
              >
                <Container
                  maxWidth={'xl'}
                  style={{
                      width: '100%',
                      padding: "27px 0px",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}>

                    {
                        selectedConfiguration?.url === "bc/general" ?
                            <Grid container spacing={2} marginTop="0" marginLeft="0px" sx={{
                                padding: {xs: " 0 20px 0 0", sm: ''},
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                            }}>
                                <Grid item xs={12} sm={5}>
                                    <Box sx={{width: '100%', height: '100%',display:'flex', flexDirection: 'column', gap: 2}}>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'white'}
                                            fontWeight={'400'}
                                            sx={{

                                                cursor: 'pointer',
                                                color: "#FFF",
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17px /* 121.429% */",
                                                textDecoration: 'underline',
                                                whiteSpace: {xs: '', md: 'pre-line'}, // Permite que \n haga saltos de línea

                                            }}

                                        >
                                            {'Aviso de Privacidad'}

                                        </Typography>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'white'}
                                            fontWeight={'400'}
                                            sx={{
                                                cursor: 'pointer',
                                                color: "#FFF",
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17px /* 121.429% */",
                                                whiteSpace: {xs: '', md: 'pre-line'}, // Permite que \n haga saltos de línea

                                            }}
                                        >
                                            {'Las marcas que ostentan el símbolo “R”, son marcas debidamente\nregistradas y son propiedad de sus respectivos titulares. INTERPROTECCION ASISTENCIAS S.A.P.I de C.V. y BRADESCARD MEXICO S. de R.L. no tienen relación comercial o patrocinio con estas marcas.'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={5} sx={{
                                    justifyContent: {xs: 'center', sm: 'flex-end'}, display: 'flex'
                                }}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.logo[1]}
                                        sx={{
                                            width: {xs: 180, md: 160},
                                            maxWidth: {xs: 180, md: 180},
                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>

                            </Grid>
                            :
                            <Grid container spacing={selectedConfiguration?.url === "asistenciatotal/gcc" ? 1 : 3}
                                  marginTop="0" marginLeft="0px" sx={{
                                justifyContent: 'center',
                                padding: {xs: " 0 20px 0 0", sm: ''},
                                alignItems: 'center',
                                display: 'flex',
                                width: '100%',
                            }}>

                                <Grid item xs={12} sm={4} md={4.8}>
                                    <Box sx={{width: '100%', height: '100%',display:'flex', flexDirection: 'column', gap: 2}}>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'white'}
                                            fontWeight={'400'}
                                            sx={{

                                                cursor: 'pointer',
                                                color: "#FFF",
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17px /* 121.429% */",
                                                textDecoration: 'underline',
                                                whiteSpace: {xs: '', md: 'pre-line'}, // Permite que \n haga saltos de línea

                                            }}

                                        >
                                            {'Aviso de Privacidad'}

                                        </Typography>
                                        <Typography
                                            variant={'subtitle2'}
                                            color={'white'}
                                            fontWeight={'400'}
                                            sx={{
                                                cursor: 'pointer',
                                                color: "#FFF",
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                lineHeight: "17px /* 121.429% */",
                                                whiteSpace: {xs: '', md: 'pre-line'}, // Permite que \n haga saltos de línea

                                            }}
                                        >
                                            {'Las marcas que ostentan el símbolo “R”, son marcas debidamente\nregistradas y son propiedad de sus respectivos titulares. INTERPROTECCION ASISTENCIAS S.A.P.I de C.V. y BRADESCARD MEXICO S. de R.L. no tienen relación comercial o patrocinio con estas marcas.'}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={selectedConfiguration?.url === "asistenciatotal/gcc" ? 3 : 4}
                                      md={4} sx={{
                                    justifyContent: {xs: 'center', sm: 'flex-end'}, display: 'flex'
                                }}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.logo[1]}
                                        sx={{
                                            width: {xs: 180, md: 160},
                                            maxWidth: {xs: 180, md: 180},
                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={selectedConfiguration?.url === "asistenciatotal/gcc" ? 4.5 : 3.4}
                                      md={3}
                                      sx={{justifyContent: 'center', display: 'flex', marginTop: {xs: 2, sm: 0}}}>
                                    <Box
                                        component={'img'}
                                        src={selectedConfiguration.sublogo[1]}
                                        sx={{
                                            width: {xs: 'auto', md: 'auto'},

                                        }}
                                        alt={'Inter, seguro, seguro'}
                                    />
                                </Grid>
                            </Grid>
                    }

                </Container>
              </Box>
            }
        </>

    )
}
export default Footer
