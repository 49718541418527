import * as React from "react"
import {Box} from "@mui/material";

interface SvgImageProps {
    imageUrl: string; // URL de la imagen
    altText?: string; // Texto alternativo opcional
    width?: string | number; // Ancho personalizable
    height?: string | number; // Altura personalizable
}

export const SvgImage: React.FC<SvgImageProps> = ({
                                                      imageUrl,
                                                      altText = 'Image',
                                                      width = '100%',
                                                      height = 'auto',
                                                  }) => (

    <Box
        sx={{
            width, // Ocupa el ancho especificado
            height, // Mantiene la proporción o establece un tamaño fijo
        }}
        component="img"
        src={imageUrl}
        alt={altText}
    />
)
